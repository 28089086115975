import React from "react";
import { Box, Text, Center, Heading, Container } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import {
  section_mb,
  section_mt,
  overlay_def_mt,
  CardSizeConfig,
} from "home/components/shared/pagesCfg";

const PageSectionCardNormal = ({ title, size, body, button, overlay }) => {
  const cardWidth = CardSizeConfig.cardWidth[size];
  const headingSize = CardSizeConfig.headingSize[size];
  const textStyle = CardSizeConfig.textStyle[size];

  return (
    <Container
      maxW="container.xl"
      mt={overlay ? overlay_def_mt : section_mt}
      mb={section_mb}
    >
      <Center>
        <Box
          mx={overlay ? 6 : null}
          py={[8, null, 12]}
          px={[8, null, 12]}
          maxW={cardWidth}
          shadow="3xl"
          backgroundColor="white"
          w="100%"
          zIndex={1}
        >
          {title && (
            <Heading size={headingSize} mb={6}>
              {title}
            </Heading>
          )}

          <Text textStyle={textStyle} whiteSpace="pre-line">
            {body}
          </Text>

          {button && (
            <ButtonManaged setup={button} mt={4} w={["100%", "initial"]} />
          )}
        </Box>
      </Center>
    </Container>
  );
};

export default PageSectionCardNormal;
