import qs from "qs";

export async function fetchFromCms(query) {
  const res = await fetch(`https://cms.ejsingholm.com${query}`);
  if (!res.ok) {
    console.error(res);
    throw new Error("could not fetch from cms: " + query);
  } else {
    return res.json();
  }
}

export async function fetchPage(slug, locale) {
  const query = qs.stringify(
    {
      filters: {
        slug: {
          $eq: slug,
        },
      },
      locale,
      populate: [
        "metadata",
        "content",
        "content.images",
        "content.image",
        "content.items",
        "content.items.icon",
        "content.items.buttons",
        "content.tag",
        "content.tag.icon",
        "content.items.image",
        "content.background_image",
        "content.button",
        "content.button.page",
        "content.stats",
        "content.members",
        "content.members.image",
        "content.icon",
      ],
    },
    {
      encodeValuesOnly: true,
    }
  );
  const pages = await fetchFromCms(`/api/pages?${query}`);
  return pages?.data?.[0]?.attributes;
}

export async function fetchSection(id, locale) {
  const query = qs.stringify({
    locale,
    populate: {
      content: {
        populate: "*",
      },
    },
  });
  const section = await fetchFromCms(`/api/sections/${id}?${query}`);
  return section?.data?.attributes;
}

export async function fetchLayout(locale) {
  const query = qs.stringify({
    locale,
    populate: {
      metadata: {
        populate: "*",
      },
      header_navigation_items: {
        populate: "*",
      },
      footer_navigation_items: {
        populate: "*",
      },
    },
  });
  const layout = await fetchFromCms(`/api/layout?${query}`);
  return layout?.data?.attributes;
}
