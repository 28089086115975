import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import {
  TeamMemberProps,
  TeamProps,
} from "home/components/page_sections_updated/PageSectionTeam";
import { section_my } from "home/components/shared/pagesCfg";
import Image from "next/legacy/image";

const Member = ({ image, name, role, body }: TeamMemberProps) => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      w={["100%", "375px", "100%"]}
      gap={{ md: 8 }}
    >
      <Box
        pos="relative"
        w={{ md: "315px", lg: "386px", xl: "506px" }}
        h={["346px", null, "315px", "386px", "506px"]}
      >
        <Image
          src={image.data.attributes.url}
          layout="fill"
          objectFit="cover"
          alt="profile"
        />
      </Box>
      <Flex
        w={{
          md: "calc(100% - 347px)",
          lg: "calc(100% - 418px)",
          xl: "calc(100% - 538px)",
        }}
        direction="column"
        px={[4, null, 0]}
        h={{ md: "315px", lg: "386px", xl: "506px" }}
        justify={{ md: "center", lg: "flex-end" }}
        overflow="hidden"
      >
        <Heading size="sm" mt={[4, null, 0]} mb={[2, null, 0]}>
          {name}
        </Heading>
        <Text mb={6} textStyle="subtitleMedium" color="blueGray.500">
          {role}
        </Text>
        {body && (
          <Text
            overflow="hidden"
            whiteSpace="pre-line"
            textStyle="subtitleNormal"
          >
            {body}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default function PageSectionTeamExtended({ title, members }: TeamProps) {
  return (
    <Box my={section_my} px={{ md: 5 }} maxW="container.xl" mx="auto">
      <Box textAlign="center">
        <Heading pb={[10, null, 20]} size="md">
          {title}
        </Heading>
      </Box>
      <Flex
        direction="column"
        gap={10}
        sx={{
          "@media screen and (min-width: 48em)": {
            "& div:nth-of-type(even) div:first-of-type": {
              order: 1,
            },
            gap: 20,
          },
        }}
      >
        {members.map(({ id, image, name, role, body }) => (
          <Flex justify={{ sm: "center" }} key={id}>
            <Member id={id} image={image} name={name} role={role} body={body} />
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
