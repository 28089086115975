import React from "react";
import { Box, Flex, Text, Button, Container } from "@chakra-ui/react";
import Link from "next/link";
import { Email, Instagram, Phone } from "home/lib/icons";
import { generateUrl } from "home/lib/utils";
import LanguagePicker from "home/components/shared/LanguagePicker";

const FooterColumn = ({ children, ...rest }) => (
  <Box flex={1} maxW={{ base: "100%", md: "230px" }} color="white" {...rest}>
    {children}
  </Box>
);

const PageSectionFooter = ({
  cvr,
  email,
  phone,
  links,
  address,
  instagram,
}) => {
  return (
    <Flex
      minH="330px"
      bg="blueGray.800"
      alignItems="center"
      px={{ base: 8, md: 6 }}
      py="60px"
      mt={[32, 32, 40]}
    >
      <Container maxW="1100px" h="100%" p={0}>
        <Flex
          h="100%"
          m="auto"
          justify="space-between"
          flexDir={{ base: "column", md: "row" }}
        >
          <FooterColumn>
            <Text textStyle="subtitleMedium" mb={3}>
              Ejsingholm ApS
            </Text>
            <Text textStyle="bodyNormal" color="white">
              {address}
            </Text>
            <Text textStyle="bodyNormal" color="white" mb={6}>
              CVR: {cvr}
            </Text>
          </FooterColumn>

          <FooterColumn my={{ base: "50px", md: 0 }}>
            <Flex alignItems="center" mb={3}>
              <Email fontSize="22px" color="white" fill="transparent" />
              <Text ml={4}>{email}</Text>
            </Flex>

            <Flex alignItems="center" mb={3}>
              <Phone fontSize="22px" color="white" fill="transparent" />
              <Text ml={4}>{phone}</Text>
            </Flex>

            <Flex alignItems="center" mb={3}>
              <Instagram fontSize="22px" color="white" fill="transparent" />
              <Text ml={4}>{instagram}</Text>
            </Flex>
          </FooterColumn>

          <FooterColumn>
            <Flex flexDir="column" mb={6} alignItems="start">
              {links.map(({ id, page, title }) => (
                <Link
                  key={id}
                  href={generateUrl(page.slug)}
                  passHref
                  legacyBehavior
                >
                  <Button as="a" variant="linkWhite" textStyle="bodyNormal">
                    {title}
                  </Button>
                </Link>
              ))}
            </Flex>

            <LanguagePicker />
          </FooterColumn>
        </Flex>
      </Container>
    </Flex>
  );
};

export default PageSectionFooter;
