import { Carousel } from "react-responsive-carousel";
import { Center, Box, Text, IconButton, Heading } from "@chakra-ui/react";
import { Quotes, ChevronLeft, ChevronRight } from "home/lib/icons";
import { PropTypes } from "prop-types";
import { section_my } from "home/components/shared/pagesCfg";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const showBg = (img) => {
  let data = img?.data?.attributes;
  if (data) {
    return {
      background: `linear-gradient(0deg, rgba(23, 38, 43, 0.5), rgba(23, 38, 43, 0.5)), url(${data.url})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }

  return {};
};

const indicator = (setActive, isActive) => (
  <Box
    w={4}
    h={4}
    m={2}
    onClick={setActive}
    display="inline-flex"
    bg={isActive ? "blueGray.400" : "blueGray.200"}
    borderRadius="50%"
  />
);

const DisplayQuote = ({ author, quote, arColor, qColor }) => (
  <Box mx="auto" px={{ base: 4, md: 20, lg: 0 }}>
    <Quotes color="blueGray.200" w="68px" h="57px" />
    <Heading my={6} size="md" color={qColor}>
      {quote}
    </Heading>
    <Text color={arColor}>{author}</Text>
  </Box>
);

const PageSectionQuote = ({ items, background_image }) => {
  const getImage = showBg(background_image);
  const authorArrowColor = background_image?.data ? "white" : "blueGray.900";
  const quoteColor = background_image?.data ? "white" : "coolGray.600";

  const arrowL = (onClick) => (
    <Center
      display={["none", "none", "flex"]}
      height="full"
      position="absolute"
      top="0"
      left={{ md: 25, lg: -10 }}
      zIndex="1"
    >
      <IconButton
        onClick={onClick}
        color={authorArrowColor}
        bg="transparent"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        _focus={{ outline: "none" }}
        icon={<ChevronLeft size="36px" />}
      />
    </Center>
  );

  const arrowR = (onClick) => (
    <Center
      display={["none", "none", "flex"]}
      height="full"
      position="absolute"
      top="0"
      right={{ md: 25, lg: -10 }}
      zIndex="1"
    >
      <IconButton
        onClick={onClick}
        color={authorArrowColor}
        bg="transparent"
        _hover={{ bg: "transparent" }}
        _active={{ bg: "transparent" }}
        _focus={{ outline: "none" }}
        icon={<ChevronRight size="36px" />}
      />
    </Center>
  );

  return (
    <Center
      my={section_my}
      h="508px"
      sx={{
        "& .carousel.carousel-slider": {
          overflow: "initial",
          maxWidth: "768px",
        },
        "& .carousel .control-dots": {
          bottom: -14,
          margin: 0,
        },
        "& .carousel .slider-wrapper": {
          maxWidth: "768px",
        },
      }}
      {...getImage}
    >
      {items.length > 1 ? (
        <Carousel
          showThumbs={false}
          showStatus={false}
          renderIndicator={indicator}
          renderArrowPrev={arrowL}
          renderArrowNext={arrowR}
          infiniteLoop
        >
          {items.map(({ id, author, quote }) => (
            <DisplayQuote
              key={id}
              author={author}
              quote={quote}
              arColor={authorArrowColor}
              qColor={quoteColor}
            />
          ))}
        </Carousel>
      ) : (
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop
        >
          <DisplayQuote
            author={items[0].author}
            quote={items[0].quote}
            arColor={authorArrowColor}
            qColor={quoteColor}
          />
        </Carousel>
      )}
    </Center>
  );
};

PageSectionQuote.propTypes = {
  items: PropTypes.array.isRequired,
  image: PropTypes.string,
};

export default PageSectionQuote;
