import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Box } from "@chakra-ui/react";
import Image from "next/legacy/image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { section_my } from "home/components/shared/pagesCfg";

const PageSectionImageGallery = ({ images }) => {
  const isSingleImage = images.data.length === 1;

  return (
    <Box my={section_my}>
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        interval={6000}
        showIndicators={!isSingleImage}
        renderIndicator={(setActive, isActive) => (
          <Box
            w={4}
            h={4}
            m={2}
            onClick={setActive}
            display="inline-block"
            mb={[24, 24, 28]}
            backgroundColor={isActive ? "white" : "blueGray.200"}
            borderRadius="50%"
          />
        )}
        infiniteLoop
        emulateTouch
        autoPlay
      >
        {images.data.map(({ id, attributes }) => (
          <Box
            key={id}
            h={["400px", "400px", "600px", isSingleImage ? "650px" : "600px"]}
            position="relative"
          >
            <Image
              src={attributes.url}
              layout="fill"
              objectFit="cover"
              alt={attributes.alternativeText || "gallery image"}
            />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default PageSectionImageGallery;
