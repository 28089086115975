import React from "react";
import { ComponentNamesEnum } from "home/lib/enums";
import PageSectionHero from "home/components/page_sections_updated/PageSectionHero";
import PageSectionCardFocus from "home/components/page_sections_updated/PageSectionCardFocus";
import PageSectionImageGallery from "home/components/page_sections_updated/PageSectionImageGallery";
import PageSectionContentText from "home/components/page_sections_updated/PageSectionContentText";
import PageSectionQuote from "home/components/page_sections_updated/PageSectionQuote";
import PageSectionCardContact from "home/components/page_sections_updated/PageSectionCardContact";
import PageSectionCardImage from "home/components/page_sections_updated/PageSectionCardImage.tsx";
import PageSectionCardNormal from "home/components/page_sections_updated/PageSectionCardNormal";
import PageSectionImageFocus from "home/components/page_sections_updated/PageSectionImageFocus";
import PageSectionContentImageText from "home/components/page_sections_updated/PageSectionContentImageText";
import PageSectionHeroTenant from "home/components/page_sections_updated/PageSectionHeroTenant";
import PageSectionCardStats from "home/components/page_sections_updated/PageSectionCardStats";
import PageSectionTeam from "home/components/page_sections_updated/PageSectionTeam";
import PageSectionCTA from "home/components/page_sections_updated/PageSectionCTA";
import PageSectionFacilities from "home/components/page_sections_updated/PageSectionFacilities";

class PageComponentsFactory {
  list = {
    [ComponentNamesEnum.HERO]: PageSectionHero,
    [ComponentNamesEnum.QUOTE]: PageSectionQuote,
    [ComponentNamesEnum.CARD_FOCUS]: PageSectionCardFocus,
    [ComponentNamesEnum.CARD_IMAGE]: PageSectionCardImage,
    [ComponentNamesEnum.TENANT_HERO]: PageSectionHeroTenant,
    [ComponentNamesEnum.IMAGE_FOCUS]: PageSectionImageFocus,
    [ComponentNamesEnum.CARD_NORMAL]: PageSectionCardNormal,
    [ComponentNamesEnum.CARD_CONTACT]: PageSectionCardContact,
    [ComponentNamesEnum.CONTENT_TEXT]: PageSectionContentText,
    [ComponentNamesEnum.IMAGE_GALLERY]: PageSectionImageGallery,
    [ComponentNamesEnum.CONTENT_IMAGE_TEXT]: PageSectionContentImageText,
    [ComponentNamesEnum.CARD_STATS]: PageSectionCardStats,
    [ComponentNamesEnum.TEAM]: PageSectionTeam,
    [ComponentNamesEnum.CTA]: PageSectionCTA,
    [ComponentNamesEnum.FACILITIES]: PageSectionFacilities,
  };

  getComponent(content, options) {
    const sectionName = content.__component;
    const Component = this.list[sectionName];

    return <Component {...content} {...options} />;
  }
}

export const getSection = new PageComponentsFactory();
