import { Icon, createIcon } from "@chakra-ui/react";
import {
  FiPlus as Plus,
  FiMinus as Minus,
  FiPhone as Phone,
  FiMail as Mail,
  FiUsers as Users,
  FiMessageCircle as Message,
  FiChevronLeft as ChevronLeft,
  FiChevronRight as ChevronRight,
  FiTrash2 as Trash,
  FiEdit as Edit,
  FiCalendar,
  FiPenTool,
  FiInfo,
  FiDownload,
  FiFileText,
  FiCheckSquare,
  FiXSquare,
  FiUser,
  FiChevronDown,
  FiLink,
  FiMapPin,
  FiPaperclip,
  FiInstagram,
} from "react-icons/fi";

const OpenDoor = createIcon({
  displayName: "OpenDoor",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M19 3.51H15.53C15.3851 3.2752 15.2065 3.06294 15 2.88C14.6905 2.6004 14.3261 2.3884 13.9301 2.25754C13.5341 2.12668 13.1151 2.07984 12.7 2.12L6.69998 2.72C5.96256 2.7941 5.27867 3.13855 4.78012 3.68694C4.28158 4.23534 4.00368 4.94887 3.99998 5.69V18.1C3.996 18.8447 4.26917 19.5643 4.76636 20.1188C5.26355 20.6733 5.94921 21.0231 6.68998 21.1L12.69 21.7H12.98C13.7756 21.7 14.5387 21.3839 15.1013 20.8213C15.6639 20.2587 15.98 19.4956 15.98 18.7V5.51H17.98V20.9C17.98 21.1652 18.0853 21.4196 18.2729 21.6071C18.4604 21.7946 18.7148 21.9 18.98 21.9C19.2452 21.9 19.4996 21.7946 19.6871 21.6071C19.8746 21.4196 19.98 21.1652 19.98 20.9V4.51C19.98 4.24821 19.8774 3.99684 19.6942 3.80987C19.511 3.6229 19.2617 3.51523 19 3.51ZM14 18.7C13.9995 18.8371 13.9709 18.9727 13.9159 19.0983C13.8608 19.2238 13.7805 19.3368 13.68 19.43C13.5773 19.5217 13.4568 19.5913 13.3261 19.6343C13.1953 19.6773 13.057 19.6929 12.92 19.68L6.91998 19.08C6.67426 19.0528 6.44732 18.9356 6.28297 18.7509C6.11861 18.5662 6.02848 18.3272 6.02998 18.08V5.69C6.02848 5.44278 6.11861 5.20377 6.28297 5.0191C6.44732 4.83443 6.67426 4.71719 6.91998 4.69L12.92 4.09H13C13.2444 4.0931 13.4791 4.18559 13.66 4.35C13.7605 4.44324 13.8408 4.55615 13.8959 4.68174C13.9509 4.80732 13.9795 4.94288 13.98 5.08L14 18.7Z"
      fill="currentColor"
    />,
    <path
      key={1}
      d="M11.69 13.3C11.4248 13.3 11.1704 13.1946 10.9829 13.0071C10.7954 12.8196 10.69 12.5652 10.69 12.3V11.72C10.69 11.4548 10.7954 11.2004 10.9829 11.0129C11.1704 10.8254 11.4248 10.72 11.69 10.72C11.9552 10.72 12.2096 10.8254 12.3971 11.0129C12.5846 11.2004 12.69 11.4548 12.69 11.72V12.3C12.69 12.5652 12.5846 12.8196 12.3971 13.0071C12.2096 13.1946 11.9552 13.3 11.69 13.3Z"
      fill="currentColor"
    />,
  ],
});

const EjsingholmStripes = createIcon({
  displayName: "EjsingholmStripes",
  viewBox: "0 0 14 18",
  path: [
    <path
      key={0}
      d="m13.9267 15.1267h-13.593372v1.96h13.593372z"
      fill="currentColor"
    />,
    <path
      key={1}
      d="m13.9267 8.36667h-13.593372v1.96003h13.593372z"
      fill="currentColor"
    />,
    <path
      key={2}
      d="m13.9266 4.49335-6.80002-1.86666-6.793328 1.86666v-1.96l6.793328-1.866663 6.80002 1.866663z"
      fill="currentColor"
    />,
  ],
});

const Star = createIcon({
  displayName: "Star",
  viewBox: "0 0 20 19",
  path: [
    <path
      key={0}
      d="M10.0001 0.666668C10.3807 0.666668 10.7283 0.88274 10.8968 1.22404L13.2393 5.9698L18.478 6.73551C18.8546 6.79055 19.1673 7.05456 19.2846 7.41657C19.402 7.77858 19.3037 8.17583 19.0311 8.44136L15.2412 12.1327L16.1357 17.3476C16.2 17.7228 16.0458 18.102 15.7378 18.3257C15.4298 18.5494 15.0215 18.5789 14.6846 18.4017L10.0001 15.9382L5.3155 18.4017C4.97859 18.5789 4.57029 18.5494 4.26232 18.3257C3.95434 18.102 3.80009 17.7228 3.86444 17.3476L4.75886 12.1327L0.968983 8.44136C0.696365 8.17583 0.598092 7.77858 0.71547 7.41657C0.832848 7.05456 1.14553 6.79055 1.52209 6.73551L6.76079 5.9698L9.10334 1.22404C9.27181 0.88274 9.61943 0.666668 10.0001 0.666668Z"
      fill="currentColor"
    />,
  ],
});

<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
></svg>;

const Apartment = createIcon({
  displayName: "Apartment",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M20.68 3.89L13.39 2.51C12.7052 2.39008 12.0048 2.39008 11.32 2.51L4 3.89C3.86868 3.9143 3.74343 3.96422 3.6314 4.03692C3.51937 4.10962 3.42276 4.20367 3.34708 4.31371C3.2714 4.42375 3.21813 4.54761 3.19032 4.67824C3.16251 4.80886 3.1607 4.94368 3.185 5.075C3.20929 5.20632 3.25922 5.33157 3.33192 5.4436C3.40462 5.55563 3.49867 5.65224 3.60871 5.72792C3.71874 5.8036 3.84261 5.85687 3.97323 5.88468C4.10386 5.91249 4.23868 5.9143 4.37 5.89L4.87 5.8V19.9C4.87 20.1652 4.97536 20.4196 5.16289 20.6071C5.35043 20.7946 5.60478 20.9 5.87 20.9H18.81C19.0752 20.9 19.3296 20.7946 19.5171 20.6071C19.7046 20.4196 19.81 20.1652 19.81 19.9V5.76L20.31 5.85C20.373 5.85976 20.437 5.85976 20.5 5.85C20.6313 5.86182 20.7637 5.84766 20.8895 5.80832C21.0154 5.76899 21.1322 5.70525 21.2335 5.62075C21.3347 5.53625 21.4183 5.43264 21.4794 5.31583C21.5406 5.19903 21.5782 5.07132 21.59 4.94C21.6018 4.80868 21.5877 4.67632 21.5483 4.55047C21.509 4.42462 21.4452 4.30775 21.3607 4.20654C21.2762 4.10532 21.1726 4.02174 21.0558 3.96056C20.939 3.89939 20.8113 3.86182 20.68 3.85V3.89ZM17.81 18.89H6.9V5.38L11.69 4.48C12.1297 4.40011 12.5803 4.40011 13.02 4.48L17.81 5.38V18.89Z"
      fill="currentColor"
    />,
    <path
      key={1}
      d="M10.14 17C9.8748 17 9.62044 16.8946 9.43291 16.7071C9.24537 16.5196 9.14001 16.2652 9.14001 16V15.56C9.14001 15.2948 9.24537 15.0404 9.43291 14.8529C9.62044 14.6654 9.8748 14.56 10.14 14.56C10.4052 14.56 10.6596 14.6654 10.8471 14.8529C11.0347 15.0404 11.14 15.2948 11.14 15.56V16C11.14 16.2652 11.0347 16.5196 10.8471 16.7071C10.6596 16.8946 10.4052 17 10.14 17Z"
      fill="currentColor"
    />,
    <path
      key={2}
      d="M14.56 17C14.2948 17 14.0404 16.8946 13.8529 16.7071C13.6654 16.5196 13.56 16.2652 13.56 16V15.56C13.56 15.2948 13.6654 15.0404 13.8529 14.8529C14.0404 14.6654 14.2948 14.56 14.56 14.56C14.8252 14.56 15.0796 14.6654 15.2671 14.8529C15.4546 15.0404 15.56 15.2948 15.56 15.56V16C15.56 16.2652 15.4546 16.5196 15.2671 16.7071C15.0796 16.8946 14.8252 17 14.56 17Z"
      fill="currentColor"
    />,
    <path
      key={3}
      d="M10.14 13.05C9.8748 13.05 9.62044 12.9446 9.43291 12.7571C9.24537 12.5696 9.14001 12.3152 9.14001 12.05V11.62C9.14001 11.3548 9.24537 11.1004 9.43291 10.9129C9.62044 10.7254 9.8748 10.62 10.14 10.62C10.4052 10.62 10.6596 10.7254 10.8471 10.9129C11.0347 11.1004 11.14 11.3548 11.14 11.62V12.05C11.14 12.3152 11.0347 12.5696 10.8471 12.7571C10.6596 12.9446 10.4052 13.05 10.14 13.05Z"
      fill="currentColor"
    />,
    <path
      key={4}
      d="M14.56 13.05C14.2948 13.05 14.0404 12.9446 13.8529 12.7571C13.6654 12.5696 13.56 12.3152 13.56 12.05V11.62C13.56 11.3548 13.6654 11.1004 13.8529 10.9129C14.0404 10.7254 14.2948 10.62 14.56 10.62C14.8252 10.62 15.0796 10.7254 15.2671 10.9129C15.4546 11.1004 15.56 11.3548 15.56 11.62V12.05C15.56 12.3152 15.4546 12.5696 15.2671 12.7571C15.0796 12.9446 14.8252 13.05 14.56 13.05Z"
      fill="currentColor"
    />,
    <path
      key={5}
      d="M10.14 9.11C9.8748 9.11 9.62044 9.00464 9.43291 8.81711C9.24537 8.62957 9.14001 8.37522 9.14001 8.11V7.68C9.14001 7.41478 9.24537 7.16043 9.43291 6.97289C9.62044 6.78536 9.8748 6.68 10.14 6.68C10.4052 6.68 10.6596 6.78536 10.8471 6.97289C11.0347 7.16043 11.14 7.41478 11.14 7.68V8.11C11.14 8.37522 11.0347 8.62957 10.8471 8.81711C10.6596 9.00464 10.4052 9.11 10.14 9.11Z"
      fill="currentColor"
    />,
    <path
      key={6}
      d="M14.56 9.11C14.2948 9.11 14.0404 9.00464 13.8529 8.81711C13.6654 8.62957 13.56 8.37522 13.56 8.11V7.68C13.56 7.41478 13.6654 7.16043 13.8529 6.97289C14.0404 6.78536 14.2948 6.68 14.56 6.68C14.8252 6.68 15.0796 6.78536 15.2671 6.97289C15.4546 7.16043 15.56 7.41478 15.56 7.68V8.11C15.56 8.37522 15.4546 8.62957 15.2671 8.81711C15.0796 9.00464 14.8252 9.11 14.56 9.11Z"
      fill="currentColor"
    />,
  ],
});

const PdfFile = createIcon({
  displayName: "PdfFile",
  viewBox: "0 0 20 20",
  fill: "none",
  path: (
    <path
      d="M3.33325 17.5V13.75H4.99992C5.33144 13.75 5.64938 13.8817 5.8838 14.1161C6.11822 14.3505 6.24992 14.6685 6.24992 15C6.24992 15.3315 6.11822 15.6495 5.8838 15.8839C5.64938 16.1183 5.33144 16.25 4.99992 16.25H3.33325M15.4166 15.9583H13.3333M15.8333 13.75H13.3333V17.5M16.6666 10.8333V7.01167C16.6665 6.79067 16.5787 6.57874 16.4224 6.4225L12.7441 2.74417C12.5878 2.58788 12.3759 2.50005 12.1549 2.5H4.99992C4.55789 2.5 4.13397 2.67559 3.82141 2.98816C3.50885 3.30072 3.33325 3.72464 3.33325 4.16667V10.8333M16.6666 7.5H13.3333C12.8912 7.5 12.4673 7.3244 12.1547 7.01184C11.8422 6.69928 11.6666 6.27536 11.6666 5.83333V2.5M8.33325 13.75H9.58325C10.0253 13.75 10.4492 13.9256 10.7618 14.2382C11.0743 14.5507 11.2499 14.9746 11.2499 15.4167V15.8333C11.2499 16.2754 11.0743 16.6993 10.7618 17.0118C10.4492 17.3244 10.0253 17.5 9.58325 17.5H8.33325V13.75Z"
      strokeWidth="1.25"
      stroke="#0C738E"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

const Quotes = createIcon({
  displayName: "Quotes",
  viewBox: "0 0 69 57",
  path: [
    <path
      key={0}
      d="M39.1718 14.5091C39.1718 21.2455 43.8435 27.7227 51.6298 28.7591C50.3321 36.5318 43.3244 44.3045 38.9122 47.6727L48.5153 57C59.6756 47.1545 68.5 33.9409 68.5 15.5455C68.5 5.95909 62.0114 0 53.187 0C45.1412 0 39.1718 6.47727 39.1718 14.5091ZM0.759542 14.5091C0.759542 21.2455 5.4313 27.7227 13.2176 28.7591C11.9198 36.5318 4.91221 44.3045 0.5 47.6727L10.1031 57C21.2634 47.1545 30.0878 33.9409 30.0878 15.5455C30.0878 5.95909 23.5992 0 14.7748 0C6.72901 0 0.759542 6.47727 0.759542 14.5091Z"
      fill="currentColor"
    />,
  ],
});

const LoginUser = createIcon({
  displayName: "User",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M20 19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={1}
      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

const Menu = createIcon({
  displayName: "Menu",
  viewBox: "0 0 30 24",
  path: [
    <path
      key={0}
      d="M1.4.44a1.36 1.36 0 1 0 0 2.72h27.2a1.36 1.36 0 1 0 0-2.72H1.4Zm0 10.2a1.36 1.36 0 1 0 0 2.72h27.2a1.362 1.362 0 0 0 .526-2.622 1.36 1.36 0 0 0-.526-.098H1.4Zm0 10.2a1.36 1.36 0 1 0 0 2.72h27.2a1.362 1.362 0 0 0 .526-2.622 1.36 1.36 0 0 0-.526-.098H1.4Z"
      fill="currentColor"
    />,
  ],
});

const Close = createIcon({
  displayName: "Close",
  viewBox: "0 0 20 21",
  path: [
    <path
      key={0}
      d="M19.375 20.616a.62.62 0 0 1-.442-.183L10 11.501l-8.933 8.932A.622.622 0 0 1 0 19.991c0-.166.065-.325.182-.442l8.934-8.933L.183 1.683A.619.619 0 0 1 0 1.241C0 1.075.065.917.183.8A.619.619 0 0 1 .625.616c.167 0 .324.065.442.184L10 9.732 18.933.8a.62.62 0 0 1 1.02.203.623.623 0 0 1-.136.681l-8.934 8.933 8.934 8.934a.627.627 0 0 1-.442 1.066Z"
      fill="currentColor"
    />,
  ],
});

const Email = createIcon({
  displayName: "Mail",
  viewBox: "0 0 24 24",
  path: [
    <path
      key={0}
      d="M19 18.981H5a2 2 0 0 1-2-2V7.019a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9.963a2 2 0 0 1-2 1.999v0Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key={1}
      d="m17 9-5 3-5-3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});

const Calendar = (props) => <Icon as={FiCalendar} {...props} />;
const PenTool = (props) => <Icon as={FiPenTool} {...props} />;
const Info = (props) => <Icon as={FiInfo} {...props} />;
const Download = (props) => <Icon as={FiDownload} {...props} />;
const FileText = (props) => <Icon as={FiFileText} {...props} />;
const CheckSquare = (props) => <Icon as={FiCheckSquare} {...props} />;
const XSquare = (props) => <Icon as={FiXSquare} {...props} />;
const User = (props) => <Icon as={FiUser} {...props} />;
const ArrowDown = (props) => <Icon as={FiChevronDown} {...props} />;
const Instagram = (props) => <Icon as={FiInstagram} {...props} />;
const LinkIco = (props) => <Icon as={FiLink} {...props} />;
const MapPin = (props) => <Icon as={FiMapPin} {...props} />;
const Paperclip = (props) => <Icon as={FiPaperclip} {...props} />;

export {
  Plus,
  Minus,
  Phone,
  Mail,
  Users,
  Message,
  ChevronLeft,
  ChevronRight,
  OpenDoor,
  EjsingholmStripes,
  Trash,
  Edit,
  Calendar,
  PenTool,
  Info,
  Download,
  FileText,
  Star,
  CheckSquare,
  XSquare,
  Apartment,
  User,
  Quotes,
  LoginUser,
  Menu,
  Close,
  ArrowDown,
  Instagram,
  Email,
  LinkIco,
  MapPin,
  Paperclip,
  PdfFile,
};
