import Image from "next/legacy/image";
import Link from "next/link";
import { generateUrl } from "home/lib/utils";
import { ImageSources } from "home/lib/image_sources";

const AppLogotype = ({ isDark }) => (
  <Link href={generateUrl("/")} passHref legacyBehavior>
    <a>
      <Image
        src={isDark ? ImageSources.logoDark : ImageSources.logo}
        width={147}
        height={31}
        alt="logo"
      />
    </a>
  </Link>
);

export default AppLogotype;
