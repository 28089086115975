export const SizeConfig = {
  heightSizes: {
    sm: "380px",
    md: "665px",
    lg: "780px",
  },
  CardFocusSectionWidth: {
    md: "1044px",
    sm: "830px",
  },
};

export const CardSizeConfig = {
  cardWidth: {
    sm: "830px",
    md: "1044px",
  },
  headingSize: {
    sm: "sm",
    md: "md",
  },
  textStyle: {
    sm: "subtitleNormal",
    md: "titleSm",
  },
};

export const buttonVariant = Object.freeze({
  primary: "solidCyan",
  secondary: "outlineCyan",
});

export const optionsInit = {
  section_settings: {
    "sections.card-focus": {
      overlay: false,
    },
    "sections.card-stats": {
      overlay: false,
    },
    "sections.card-image": {
      overlay: false,
    },
    "sections.card-normal": {
      overlay: false,
    },
    "sections.card-contact": {
      overlay: true,
    },
  },
};

export const section_my = {
  base: 20,
  md: 40,
};

export const section_mt = {
  base: 20,
  md: 40,
};

export const section_mb = {
  base: 20,
  md: 40,
};

export const general_py = 16;

export const overlay_def_mt = {
  base: -40,
  md: -60,
};
