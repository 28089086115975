import React from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";

const InterestListSummary = () => {
  const { t } = useTranslation("common");

  return (
    <Center>
      <Text my={5}>{t("interest_list.if_you_have_other_questions")}</Text>
    </Center>
  );
};

export default InterestListSummary;
