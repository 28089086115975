import request from "graphql-request";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { fetchPage } from "./cms";
import { PROPERTIES_QUERY, useAddInterestEntryMutation } from "./graphql";

async function fetchProperties(locale) {
  const res = await request("/admin/graphql/public", PROPERTIES_QUERY);

  const properties = await Promise.all(
    res.properties.map(async (property) => {
      if (!property.enableInterestList) return null;
      const page = await fetchPage(property.publicPageSlug, locale);
      if (!page) return null;
      return { ...property, image: page.metadata?.image };
    })
  );

  return properties.filter(Boolean);
}

export function useInterestList() {
  const { locale } = useRouter();
  const [properties, setProperties] = useState(null);
  const { mutateAsync: submit, ...mutation } = useAddInterestEntryMutation();

  useEffect(() => {
    fetchProperties(locale)
      .then(setProperties)
      .catch((e) => {
        console.error(e);
        setProperties(false);
      });
  }, [locale]);

  const isError = mutation.isError || properties === false;
  const isLoading = mutation.isLoading || properties === null;

  return {
    isLoading,
    isError,
    properties,
    submit,
  };
}
