
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Head from "next/head";
import { getSection } from "home/components/shared/PageComponentsFactory";
import { Fragment } from "react";
import { getKey } from "home/lib/utils";
import PageSectionHeader from "home/components/page_sections_updated/PageSectionHeader";
import PageSectionFooter from "home/components/page_sections_updated/PageSectionFooter";
import { fetchSection, fetchLayout, fetchPage } from "home/lib/cms";
import { optionsInit } from "home/components/shared/pagesCfg";

function Page({ layout, metadata, content }) {
  const {
    cvr,
    email,
    phone,
    address,
    instagram,
    header_navigation_items,
    footer_navigation_items,
  } = layout;

  const renderSection = (content) => {
    let placement = "left";
    return content.map((c, i) => {
      let prevComponent = content[i - 1]?.__component;
      let component = c.__component;
      let options = { ...optionsInit?.section_settings[component] } || {};

      if (component.includes("card") && !prevComponent.includes("card")) {
        options.overlay = true;
      }

      if (
        component === "sections.content-image-text" &&
        component === prevComponent
      ) {
        options.placement =
          placement === "left"
            ? "right"
            : placement === "right"
            ? "left"
            : "right";
        placement = placement === "left" ? "right" : "left";
      }

      return (
        <Fragment key={getKey()}>
          {getSection.getComponent(c, options)}
        </Fragment>
      );
    });
  };

  return (
    <>
      <Head>
        <title>{metadata.title}</title>
        <meta name="title" content={metadata.title} />
        <meta name="description" content={metadata.description} />
      </Head>

      <PageSectionHeader menuItems={header_navigation_items} />

      {renderSection(content)}

      <PageSectionFooter
        {...{
          cvr,
          email,
          phone,
          address,
          instagram,
          feedbacks: 99,
          links: footer_navigation_items,
        }}
      />
    </>
  );
}

 async function _getStaticProps({ req, locale, params }) {
  const slug = (params.path || []).join("/") || "index";
  const page = await fetchPage(slug, locale);
  const layout = await fetchLayout(locale);

  if (!layout || !page) {
    return {
      notFound: !Boolean(page),
      revalidate: 5,
    };
  }

  const metadata = {
    title: page.metadata.title || layout.metadata.title,
    description: page.metadata.description || layout.metadata.description,
    image: page.metadata.image || layout.metadata.image,
  };

  const resolvedContent = await Promise.all(
    page.content.map(async (c) => {
      if (c.__component === "sections.reference") {
        if (c.section?.data?.id) {
          try {
            const reference = await fetchSection(c.section.data.id, locale);
            return reference.content;
          } catch (e) {
            console.error(e);
            return null;
          }
        } else {
          return null;
        }
      } else {
        return c;
      }
    })
  );

  const content = resolvedContent.flat().filter(Boolean);

  return {
    props: { ...page, content, layout, metadata, locale },
    revalidate: 5,
  };
}

export function getStaticPaths() {
  return { paths: [], fallback: "blocking" };
}

export default Page;


    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[[...path]]',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  