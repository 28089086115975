import { Flex, Box, Heading, Text } from "@chakra-ui/react";
import {
  TeamMemberProps,
  TeamProps,
} from "home/components/page_sections_updated/PageSectionTeam";
import { section_my } from "home/components/shared/pagesCfg";
import Image from "next/legacy/image";

const Member = ({ name, role, image }: TeamMemberProps) => {
  return (
    <Flex direction="column">
      <Box
        pos="relative"
        w={["100%", "375px", "315px", "290px", "291px"]}
        h={{ base: "320px", md: "315px", lg: "290px", xl: "291px" }}
      >
        <Image
          priority
          src={image.data.attributes.url}
          layout="fill"
          objectFit="cover"
          alt="profile"
        />
      </Box>
      <Flex
        direction="column"
        px={2}
        maxW={["100%", "375px", "315px", "290px", "291px"]}
      >
        <Heading mt={4} mb={2} size="sm">
          {name}
        </Heading>
        <Text textStyle="subtitleMedium" color="blueGray.500">
          {role}
        </Text>
      </Flex>
    </Flex>
  );
};

export default function PageSectionTeam({ title, members }: TeamProps) {
  return (
    <Box my={section_my} mx="auto" maxW="1325px">
      <Box pb={{ base: 10, md: 12 }} textAlign="center">
        <Heading size="md">{title}</Heading>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        wrap={{ md: "wrap" }}
        gap={{ base: 10, md: 12 }}
        justify={{ md: "center" }}
      >
        {members.map(({ id, name, role, image }) => (
          <Box
            key={id}
            className="boxit"
            alignSelf={{ sm: "center", md: "flex-start" }}
          >
            <Member id={id} name={name} role={role} image={image} />
          </Box>
        ))}
      </Flex>
    </Box>
  );
}
