import React from "react";
import { Box, Heading, Text, Flex, Center } from "@chakra-ui/react";
import { section_my } from "home/components/shared/pagesCfg";
import ButtonManaged from "home/components/shared/ButtonManaged";

const bg = (img) => {
  return {
    background: `linear-gradient(0deg, rgba(23, 38, 43, 0.5), rgba(23, 38, 43, 0.5)), url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
};

const PageSectionImageFocus = ({ image, title, subtitle, caption, button }) => {
  const setBg = bg(image.data.attributes.url);

  return (
    <Center my={section_my} mx={{ xl: 10 }}>
      <Flex
        px={[4, 6, 8, 20, 36]}
        w="100%"
        maxW="container.xl"
        h={{ base: "calc(100vh - 128px)", md: "585px" }}
        direction="column"
        justify="center"
        align="flex-start"
        color="white"
        {...setBg}
      >
        <Box>
          {caption && (
            <Text mb={1} textStyle="titleMd">
              {caption}
            </Text>
          )}
          <Heading color="white" size="xl">
            {title}
          </Heading>
          <Text mt={1} mb={6} textStyle="titleSm">
            {subtitle}
          </Text>
        </Box>
        <Box>
          <ButtonManaged setup={button} w="max-content" />
        </Box>
      </Flex>
    </Center>
  );
};

export default PageSectionImageFocus;
