import React from "react";
import { Box, Text, Center, Heading, Image } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import {
  SizeConfig,
  section_mt,
  section_mb,
  general_py,
  overlay_def_mt,
} from "home/components/shared/pagesCfg";
import { PropTypes } from "prop-types";

const PageSectionCardFocus = ({ size, body, title, button, icon, overlay }) => {
  const sectionWidth = SizeConfig.CardFocusSectionWidth[size];
  const svg = icon?.data?.attributes.url;
  const alt = icon?.data?.attributes.alternativeText || "focus image";

  return (
    <Center
      mx={{ base: overlay ? 6 : 4, md: 6, lg: 12 }}
      mt={overlay ? overlay_def_mt : section_mt}
      mb={section_mb}
    >
      <Center
        py={general_py}
        px={[4, null, 8, null, size === "md" ? 12 : null]}
        w="100%"
        maxW={sectionWidth}
        shadow="3xl"
        bg="white"
        textAlign="center"
        flexDirection="column"
        zIndex={1}
      >
        {icon?.data && (
          <Center mb={6}>
            <Image src={svg} alt={alt} boxSize={14} />
          </Center>
        )}
        <Center flexDirection="column" gap={4}>
          <Box>
            {title && (
              <Heading size="md" color="cyan.700">
                {title}
              </Heading>
            )}
          </Box>
          <Box>
            <Text textStyle="titleSm" color="blueGray.900">
              {body}
            </Text>
          </Box>
        </Center>
        {button && (
          <Center>
            <Box w="100%" mt={6}>
              <ButtonManaged
                setup={button}
                w={{ base: "100%", md: "initial" }}
              />
            </Box>
          </Center>
        )}
      </Center>
    </Center>
  );
};

PageSectionCardFocus.defaultProps = {
  size: "md",
};

PageSectionCardFocus.propTypes = {
  size: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  title: PropTypes.string,
  button: PropTypes.object,
};

export default PageSectionCardFocus;
