import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Drawer,
  Container,
  DrawerBody,
  IconButton,
  DrawerHeader,
  useDisclosure,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import Link from "next/link";
import { Close, LoginUser, Menu } from "home/lib/icons";
import { generateUrl } from "home/lib/utils";
import { useRouter } from "next/router";
import AppLogotype from "home/components/shared/AppLogotype";
import useTranslation from "next-translate/useTranslation";

const HeaderMenu = ({ menuItems, ...rest }) =>
  menuItems.map(({ id, page, title }) => (
    <Link
      key={id}
      href={generateUrl(page.data?.attributes?.slug)}
      passHref
      legacyBehavior
    >
      <a>
        <Text textStyle="subtitleNormal" color="blueGray.200" {...rest}>
          {title}
        </Text>
      </a>
    </Link>
  ));

const PageSectionHeader = ({ menuItems }) => {
  const { t } = useTranslation("common");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();

  useEffect(() => {
    onClose();
  }, [onClose, router]);

  return (
    <Box
      h="150px"
      background="linear-gradient(180deg, rgba(15, 23, 42, 0.5) 0%, rgba(15, 23, 42, 0) 100%)"
      position="absolute"
      width="100%"
      zIndex="99"
    >
      <Container
        maxW="2000px"
        px={{ base: "16px", md: "24px", lg: "90px" }}
        pt={{ base: "43px", md: "24px" }}
      >
        <Flex alignItems="center" justify="space-between">
          <AppLogotype />

          <Flex alignItems="center" display={["none", "none", "flex"]}>
            <HeaderMenu menuItems={menuItems} mr={7} />

            <Link href="/home" passHref legacyBehavior>
              <Button as="a" variant="loginWhite">
                <LoginUser boxSize="24px" fill="transparent" mr={2} />
                {t("auth.tenantLogin")}
              </Button>
            </Link>
          </Flex>

          <IconButton
            display={["block", "block", "none"]}
            icon={<Menu fill="transparent" boxSize="30px" color="white" />}
            variant="unstyled"
            bg="transparent"
            onClick={onOpen}
          />

          <Drawer onClose={onClose} isOpen={isOpen} size="full">
            <DrawerOverlay />
            <DrawerContent bg="blueGray.800">
              <DrawerHeader p="43px 16px">
                <Flex alignItems="center" justify="space-between">
                  <AppLogotype />
                  <IconButton
                    display={["block", "block", "none"]}
                    icon={<Close boxSize="20px" color="white" />}
                    variant="unstyled"
                    bg="transparent"
                    onClick={onClose}
                  />
                </Flex>
              </DrawerHeader>
              <DrawerBody p={4}>
                <Flex flexDirection="column" alignItems="center">
                  <HeaderMenu menuItems={menuItems} mb={10} />

                  <Button
                    as="a"
                    href="/home"
                    variant="loginWhite"
                    w="100%"
                    h="56px"
                  >
                    <LoginUser boxSize="24px" fill="transparent" mr={2} />
                    {t("auth.tenantLogin")}
                  </Button>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Container>
    </Box>
  );
};

export default PageSectionHeader;
