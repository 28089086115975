import React from "react";
import { Box } from "@chakra-ui/react";
import {
  section_mt,
  section_mb,
  overlay_def_mt,
} from "home/components/shared/pagesCfg";
import { CardImage } from "home/components/page_sections_components/card_image/CardImage";
import { CardContent } from "home/components/page_sections_components/card_image/CardContent";
import { CMSButton } from "home/components/shared/CmsTypes";
import {
  PageSectionCardImageImage,
  PageSectionCardImageTag,
} from "../page_sections_components/card_image/sharedTypes";

type PageSectionCardImageProps = {
  tag: PageSectionCardImageTag | null;
  title: string;
  body: string;
  image: PageSectionCardImageImage;
  button: CMSButton[];
  right: boolean;
  overlay: boolean;
};

const PageSectionCardImage = ({
  tag,
  title,
  body,
  image,
  button, // note: this is actually an array of buttons, but the name is determined by the CMS
  right,
  overlay,
}: PageSectionCardImageProps) => {
  return (
    <Box
      px={{ base: overlay ? 6 : 0, md: 6, lg: 12 }}
      display={{ lg: "flex" }}
      flexDirection={right ? "row-reverse" : "row"}
      justifyContent="center"
      mt={overlay ? overlay_def_mt : section_mt}
      mb={section_mb}
    >
      <CardImage image={image} />

      <CardContent
        tag={tag}
        title={title}
        body={body}
        buttons={button}
        right={right}
      />
    </Box>
  );
};

export default PageSectionCardImage;
