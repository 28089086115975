import { isAfter } from "date-fns";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import shortid from "shortid";

export function tenancyMovedInOutText(t, l, startDate, endDate, isApproved) {
  if (!isApproved) {
    return t("home:creating_contract");
  }

  if (endDate) {
    if (isAfter(endDate, new Date())) {
      return `${t("home:moving_out")} ${l(endDate, "PPP")}`;
    } else {
      return `${t("home:moved_out")} ${l(endDate, "PPP")}`;
    }
  } else {
    if (isAfter(startDate, new Date())) {
      return `${t("home:moving_in")} ${l(startDate, "PPP")}`;
    } else {
      return `${t("home:moved_in")} ${l(startDate, "PPP")}`;
    }
  }
}

export function requestTitleSubtitle(t, request) {
  if (request?.apartment) {
    return [
      request.apartment.address,
      t("apartment_number", { number: request.apartment.id }),
    ];
  }

  if (request?.partnerProduct) {
    return [request.partnerProduct.partner, request.partnerProduct.name];
  }

  return [null, null];
}

export function useHomeRootPath() {
  const { locale, defaultLocale } = useRouter();

  return (
    "/" +
    [locale === defaultLocale ? null : locale, "home"].filter(Boolean).join("/")
  );
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const getKey = () => shortid.generate();

export const generateUrl = (slug, prefix = "") =>
  `${prefix}/${slug}`.replace("//", "/");
