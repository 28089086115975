import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Heading,
  Checkbox,
  ModalFooter,
} from "@chakra-ui/react";
import Image from "next/legacy/image";
import Link from "next/link";
import { LinkIco, MapPin } from "home/lib/icons";
import { ModalSteps } from "./modalConfig";
import useTranslation from "next-translate/useTranslation";
import { generateUrl } from "home/lib/utils";

const InterestListCards = ({
  properties,
  isSelectedAll,
  selectedCardIds,
  toggleSelectAll,
  handleStepChange,
  setSelectedCardIds,
}) => {
  const { t } = useTranslation("common");

  const handleLocationClick = (e, { lat, lng }) => {
    e.stopPropagation();
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };

  const toggleCardSelect = (selectedId) => {
    const alreadySelected = selectedCardIds.some((id) => id === selectedId);

    if (alreadySelected) {
      setSelectedCardIds(selectedCardIds.filter((id) => id !== selectedId));
      return;
    }

    setSelectedCardIds([...selectedCardIds, selectedId]);
  };

  return (
    <Box>
      <Heading mb={5} size="sm">
        {t("interest_list.select_interested_properties")}
      </Heading>

      <Flex flexDir="column" mb={4} alignItems="flex-end">
        <Checkbox
          onChange={toggleSelectAll}
          isChecked={isSelectedAll}
          textStyle="bodyMedium"
          size="lg"
          mb={1}
        >
          {t("interest_list.select_all")}
        </Checkbox>
        <Text textStyle="captionNormal" fontStyle="italic" color="blueGray.600">
          {t("interest_list.more_options")}
        </Text>
      </Flex>

      {properties.map(
        ({ id, image, name, address, lat, lng, publicPageSlug: slug }) => {
          const isSelected = selectedCardIds.some((i) => i === id);
          return (
            <Box
              key={id}
              border="1px solid"
              borderColor={isSelected ? "blueGray.400" : "transparent"}
              mb={4}
              borderRadius="7px"
            >
              <Flex
                cursor="pointer"
                minH="114px"
                border="1px solid"
                borderColor="blueGray.400"
                borderRadius="6px"
                overflow="hidden"
                shadow={isSelected ? "2xl" : "none"}
                onClick={() => toggleCardSelect(id)}
              >
                <Box position="relative" w="124px" flexShrink={0} bg="gray.100">
                  {image ? (
                    <Image
                      src={image.url}
                      layout="fill"
                      alt="interest image"
                      objectFit="cover"
                      sizes="124px"
                    />
                  ) : null}
                </Box>

                <Flex
                  direction={"column"}
                  justifyContent={"center"}
                  px={[3, 4]}
                  py={[3, 4]}
                >
                  <Text mb={3} textStyle="subtitleMedium">
                    {name}
                  </Text>

                  <Flex
                    direction={["column", null, "row"]}
                    alignItems={"flex-start"}
                    gap={[2, null, 4]}
                  >
                    <Button
                      onClick={(e) => handleLocationClick(e, { lat, lng })}
                      color="cyan.700"
                      textDecoration="underline"
                      variant="link"
                      size="sm"
                      d="block"
                      wordBreak={"break-word"}
                      whiteSpace={"wrap"}
                      textAlign={"left"}
                    >
                      <MapPin fontSize="18px" mr={2} />
                      {address}
                    </Button>
                    <Link href={generateUrl(slug)} passHref legacyBehavior>
                      <Button
                        color="cyan.700"
                        textDecoration="underline"
                        variant="link"
                        size="sm"
                        d="block"
                        as="a"
                        target="_blank"
                        wordBreak={"break-word"}
                        whiteSpace={"wrap"}
                        textAlign={"left"}
                      >
                        <LinkIco fontSize="18px" mr={2} />
                        {t("interest_list.show_property")}
                      </Button>
                    </Link>
                  </Flex>
                </Flex>

                <Checkbox
                  ml="auto"
                  size="lg"
                  mr={6}
                  isChecked={isSelected}
                  zIndex={-1}
                />
              </Flex>
            </Box>
          );
        }
      )}

      <ModalFooter p={0} justifyContent="center">
        <Button
          variant="solidCyan"
          size="lg"
          isDisabled={!selectedCardIds.length}
          onClick={() => handleStepChange(ModalSteps.INTEREST_FORM)}
        >
          {t("next")}
        </Button>
      </ModalFooter>
    </Box>
  );
};

export default InterestListCards;