import React from "react";
import PageSectionTeamDefault from "home/components/page_sections_updated/PageSectionTeamVariants/PageSectionTeamDefault";
import PageSectionTeamExtended from "home/components/page_sections_updated/PageSectionTeamVariants/PageSectionTeamExtended";

export interface TeamMemberProps {
  id: number;
  name: string;
  role: string;
  image: any;
  body?: string;
}

export interface TeamProps {
  title: string;
  extended?: boolean;
  members: Array<TeamMemberProps>;
}

function PageSectionTeam({ title, extended, members }: TeamProps) {
  const Content = extended ? PageSectionTeamExtended : PageSectionTeamDefault;
  return <Content title={title} members={members} />;
}

export default PageSectionTeam;
