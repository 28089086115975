import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { PageSectionCardImageImage } from "./sharedTypes";

type CardImageProps = {
  image: PageSectionCardImageImage;
};

/**
 * Handles displaying the image within the PageSectionCardImage component, with the appropriate sizing and positioning.
 *
 * @param image - The image to be displayed in the card.
 */
const CardImage = memo(function CardImage({ image }: CardImageProps) {
  return (
    <Box h={96} w={{ lg: "xl" }} position="relative">
      <Image
        priority
        src={image.data.attributes.url}
        layout="fill"
        objectFit="cover"
        alt={image.data.attributes.alternativeText || "card image"}
      />
    </Box>
  );
});

export { CardImage };
