import React from "react";
import { PropTypes } from "prop-types";
import { Text, Heading, Center } from "@chakra-ui/react";
import Image from "next/legacy/image";
import ButtonManaged from "home/components/shared/ButtonManaged";
import { SizeConfig, section_mb } from "home/components/shared/pagesCfg";

const PageSectionHero = ({
  size,
  title,
  button,
  subtitle,
  background_image,
}) => {
  const sectionSize = SizeConfig.heightSizes[size];

  return (
    <Center
      mb={section_mb}
      bg="blueGray.800"
      height={sectionSize}
      position="relative"
      sx={{
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.15), rgba(0,0,0,0.15))",
        },
      }}
    >
      {background_image?.data && (
        <Image
          src={background_image.data.attributes.url}
          layout="fill"
          objectFit="cover"
          priority
          alt={
            background_image.data.attributes.alternativeText ||
            "background image"
          }
        />
      )}

      <Center
        h="100%"
        textAlign="center"
        flexDirection="column"
        px={{ base: 6, md: 10, lg: 24 }}
        py={20}
        maxW="container.xl"
      >
        <Heading color="white" size="xl" zIndex={1}>
          {title}
        </Heading>

        {subtitle && (
          <Text
            textStyle="titleMd"
            color="white"
            mt={[3, 4]}
            zIndex={1}
            px={{ lg: 9 }}
          >
            {subtitle}
          </Text>
        )}

        {button && <ButtonManaged setup={button} zIndex={1} mt={6} />}
      </Center>
    </Center>
  );
};

PageSectionHero.propTypes = {
  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  button: PropTypes.object,
  background_image: PropTypes.object,
};

export default PageSectionHero;
