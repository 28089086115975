import { Center, Flex, Heading, Text, Box } from "@chakra-ui/react";
import ButtonManaged from "home/components/shared/ButtonManaged";
import { CMSButton } from "home/components/shared/CmsTypes";
import { section_my, general_py } from "home/components/shared/pagesCfg";

interface CTAProps {
  title: string;
  body: string;
  button: CMSButton;
}

export default function PageSectionCTA({ title, body, button }: CTAProps) {
  return (
    <Center my={section_my} px={[4, 6, 10]} maxW="container.lg" mx="auto">
      <Flex
        direction="column"
        justify="center"
        alignItems="center"
        py={general_py}
      >
        <Box textAlign="center" mb={8}>
          <Heading size="md" mb={4}>
            {title}
          </Heading>
          <Text textStyle="subtitleNormal">{body}</Text>
        </Box>

        <Box>
          <ButtonManaged setup={button} />
        </Box>
      </Flex>
    </Center>
  );
}
