export const ComponentNamesEnum = Object.freeze({
  HERO: "sections.hero",
  QUOTE: "sections.quote",
  CARD_FOCUS: "sections.card-focus",
  CARD_STATS: "sections.card-stats",
  CARD_IMAGE: "sections.card-image",
  CARD_NORMAL: "sections.card-normal",
  IMAGE_FOCUS: "sections.image-focus",
  TENANT_HERO: "sections.tenant-hero",
  CARD_CONTACT: "sections.card-contact",
  CONTENT_TEXT: "sections.content-text",
  IMAGE_GALLERY: "sections.image-gallery",
  CONTENT_IMAGE_TEXT: "sections.content-image-text",
  TEAM: "sections.team",
  CTA: "sections.cta",
  FACILITIES: "sections.facilities",
});

export const LanguagesEnum = Object.freeze({
  da: "Dansk",
  en: "English",
});
