import React from "react";
import {
  Box,
  Menu,
  Text,
  Button,
  MenuList,
  MenuItem,
  MenuButton,
} from "@chakra-ui/react";
import { LanguagesEnum } from "home/lib/enums";
import useTranslation from "next-translate/useTranslation";
import { ArrowDown } from "home/lib/icons";
import { useRouter } from "next/router";

const LanguageItem = ({ lang, langName }) => {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const onClick = () => {
    router.push({ pathname, query }, asPath, { locale: lang });
  };

  return (
    <MenuItem onClick={onClick} cursor="pointer">
      <Text ml={2} textStyle="bodyNormal">
        {langName}
      </Text>
    </MenuItem>
  );
};

const LanguagePicker = () => {
  const { lang } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button} variant="linkWhite" textStyle="bodyNormal">
        {LanguagesEnum[lang]} <ArrowDown />
      </MenuButton>
      <MenuList>
        <LanguageItem lang="da" langName="Dansk" />
        <LanguageItem lang="en" langName="English" />
      </MenuList>
    </Menu>
  );
};

export default LanguagePicker;
