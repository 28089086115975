import React from "react";
import { Box, Text, Heading, Center, Container, Flex } from "@chakra-ui/react";
import { ImageSources } from "home/lib/image_sources";
import Image from "next/legacy/image";
import {
  section_mt,
  section_mb,
  overlay_def_mt,
} from "home/components/shared/pagesCfg";

const PageSectionCardContact = ({ title, email, phone, address, overlay }) => {
  return (
    <Container
      maxW="container.xl"
      mt={overlay ? overlay_def_mt : section_mt}
      mb={section_mb}
    >
      <Center>
        <Box
          py={[8, 8, 12]}
          px={[4, 4, 12]}
          w={["100%", "830px"]}
          shadow="3xl"
          backgroundColor="white"
          zIndex={1}
        >
          <Heading size="sm">{title}</Heading>

          <Flex alignItems="center" mt={4}>
            <Image width={24} height={24} src={ImageSources.mail} alt="mail" />
            <Text ml={4}>{email}</Text>
          </Flex>

          <Flex alignItems="center" mt={4}>
            <Image
              width={24}
              height={24}
              src={ImageSources.phone}
              alt="phone"
            />
            <Text ml={4}>{phone}</Text>
          </Flex>

          <Flex alignItems="center" mt={4}>
            <Image
              width={24}
              height={24}
              src={ImageSources.pin}
              alt="address"
            />
            <Text ml={4}>{address}</Text>
          </Flex>
        </Box>
      </Center>
    </Container>
  );
};

export default PageSectionCardContact;
