import React from "react";
import {
  Box,
  Text,
  Flex,
  Input,
  Button,
  Heading,
  Container,
} from "@chakra-ui/react";
import Image from "next/legacy/image";
import useTranslation from "next-translate/useTranslation";
import { section_mb } from "home/components/shared/pagesCfg";

const PageSectionHeroTenant = ({ title, subtitle, background_image }) => {
  const { t } = useTranslation("common");

  return (
    <Box mb={section_mb} height="665px" position="relative">
      {background_image?.data && (
        <Image
          src={background_image?.data?.attributes?.url}
          layout="fill"
          objectFit="cover"
          priority
          alt={
            background_image?.data?.attributes?.alternativeText ||
            "background image"
          }
        />
      )}
      <Container maxW="572px" h="100%">
        <Flex
          h="100%"
          align="center"
          justify="center"
          textAlign="center"
          direction="column"
        >
          <Heading color="white" size="xl" zIndex={1}>
            {title}
          </Heading>

          <Box as="form" action="/requests/new" method="GET">
            <Flex justify="center" flexDir={["column", null, "row"]} my={8}>
              <Input
                mr={[0, 0, 5]}
                width="initial"
                name="apartment_number"
                size="lg"
                placeholder={t("enter_apartment_number")}
                zIndex={1}
                bg="rgba(255, 255, 255, 0.1)"
                borderColor="white"
                color="white"
                _placeholder={{ color: "white" }}
                textAlign="center"
                h="54px"
                w="240px"
                mb={[5, 5, 0]}
              />
              <Button
                type="submit"
                size="lg"
                zIndex={1}
                minW="189px"
                variant="solidCyan"
              >
                {t("apply")}
              </Button>
            </Flex>
          </Box>
          {subtitle && (
            <Text textStyle="subtitleBold" color="white" zIndex={1}>
              {subtitle}
            </Text>
          )}
        </Flex>
      </Container>
    </Box>
  );
};

export default PageSectionHeroTenant;
