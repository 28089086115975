import React from "react";
import { Box, Flex, Button, Heading, Center } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import Link from "next/link";
import { string, object, boolean } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useTranslation from "next-translate/useTranslation";
import { generateUrl } from "home/lib/utils";
import { ModalSteps } from "./modalConfig";
import { FormFieldBoolean, FormFieldEmail, FormFieldText } from "made-this-ui";

const InterestListFormValidators = (t) =>
  object().shape({
    name: string().required(t("field_required")),
    email: string().email(t("email_not_valid")).required(t("field_required")),
    phone: string().required(t("field_required")),
    consent: boolean().isTrue(t("field_required")),
  });

const InterestListForm = ({ handleStepChange, selectedCardIds, submit }) => {
  const { t } = useTranslation("common");

  const form = useForm({
    resolver: yupResolver(InterestListFormValidators(t)),
  });

  const { handleSubmit } = form;

  const onSubmit = (values) => {
    submit({ ...values, propertyIds: selectedCardIds });
    handleStepChange(ModalSteps.INTEREST_SUMMARY);
  };

  return (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      maxW="500px"
      mx="auto"
      noValidate
    >
      <FormProvider {...form}>
        <Heading mb={7} mt={4} size="sm">
          {t("interest_list.your_info")}
        </Heading>

        <FormFieldText name="name" label={t("full_name")} isRequired mb="4" />
        <FormFieldEmail
          name="email"
          label={t("email_address")}
          isRequired
          mb="4"
        />
        <FormFieldText
          name="phone"
          label={t("phone_number")}
          isRequired
          mb="4"
        />
        <FormFieldText
          name="message"
          label={t("message")}
          placeholder={t("write_message")}
          mb="4"
          isMultiline
        />
        <FormFieldBoolean name="consent" label={t("consent")} />

        <Flex mt={8} justify="center">
          <Button
            mr={2}
            onClick={() => handleStepChange(ModalSteps.INTEREST_CARDS)}
            variant="outlineCyan"
            size="lg"
          >
            {t("back")}
          </Button>
          <Button type="submit" variant="solidCyan" size="lg">
            {t("send")}
          </Button>
        </Flex>

        <Center mt="6" mb="2">
          <Link href={generateUrl("privatlivspolitik")} passHref legacyBehavior>
            <Button
              as="a"
              variant="linkWhite"
              textStyle="bodyNormal"
              target="_blank"
            >
              {t("privacy_policy")}
            </Button>
          </Link>
        </Center>
      </FormProvider>
    </Box>
  );
};

export default InterestListForm;
