import InterestListForm from "./InterestListForm";
import InterestListCards from "./InterestListCards";
import InterestListSummary from "./InterestListSummary";

const ModalSteps = {
  INTEREST_CARDS: 0,
  INTEREST_FORM: 1,
  INTEREST_SUMMARY: 2,
};

const getModalConfig = (t) => [
  {
    id: ModalSteps.INTEREST_CARDS,
    component: InterestListCards,
    title: t("interest_list.interest"),
    subTitle: t("interest_list.waiting_list_question"),
  },
  {
    id: ModalSteps.INTEREST_FORM,
    component: InterestListForm,
    title: t("interest_list.interest"),
    subTitle: t("interest_list.waiting_list_question"),
  },
  {
    id: ModalSteps.INTEREST_SUMMARY,
    component: InterestListSummary,
    title: t("interest_list.thank_you_for_request"),
    subTitle: t("interest_list.will_process_form"),
  },
];

const getActiveStepById = (stepId, t) =>
  getModalConfig(t).find(({ id }) => id === stepId);

export { ModalSteps, getActiveStepById };
