import { Flex, Box, Text, Heading, Button, Link } from "@chakra-ui/react";
import Image from "next/legacy/image";
import { section_my } from "home/components/shared/pagesCfg";

interface ContentImageTextProps {
  items: Array<SingleItemProps>;
  placement?: string;
}

interface SingleItemProps {
  isSingle?: boolean;
  isDouble?: boolean;
  id: number;
  title: string;
  image: Record<string, any>;
  body: string;
  buttons: {
    title: string;
    url: string;
    variant: string;
  }[];
}

const RenderItem = ({
  isSingle,
  isDouble,
  title,
  image,
  body,
  buttons,
}: SingleItemProps) => {
  return (
    <>
      <Box
        position="relative"
        w="100%"
        h={[
          "296px",
          null,
          "360px",
          null,
          isSingle || isDouble ? "343px" : "296px",
        ]}
      >
        <Image
          src={image.data.attributes.url}
          layout="fill"
          objectFit="cover"
          alt={image.data.attributes.alternativeText || "content image"}
        />
      </Box>
      <Box
        px={4}
        pt={[8, null, null, null, 0]}
        w="100%"
        h={{ xl: isSingle || isDouble ? "343px" : "296px" }}
        overflow="hidden"
      >
        <Heading size="sm">{title}</Heading>
        <Text pt={3} whiteSpace="pre-line" textStyle="subtitleNormal">
          {body}
        </Text>

        {buttons && (
          <Flex mt={8} wrap="wrap" gap={4} justify="right">
            {buttons.map((button, index) => (
              <Link key={index} href={button.url}>
                <Button
                  variant={
                    button.variant === "primary" ? "solidCyan" : "outlineCyan"
                  }
                  px={6}
                  py={3}
                >
                  {button.title}
                </Button>
              </Link>
            ))}
          </Flex>
        )}
      </Box>
    </>
  );
};

export default ContentImageText;

function ContentImageText({ items, placement }: ContentImageTextProps) {
  const isSingle = items.length === 1;
  const isRight = isSingle && placement === "right";
  const isDouble = items.length === 2;

  return (
    <Box mx="auto" maxW="container.xl" my={section_my}>
      <Flex gap={10} wrap="wrap" justify="center">
        {items.map(({ id, title, image, body, buttons }) => (
          <Flex
            key={id}
            basis={{
              base: "100%",
              xl: isSingle ? "100%" : isDouble ? "48%" : "31%",
            }}
            direction={{
              base: "column",
              xl: isRight ? "row" : isSingle ? "row-reverse" : "column",
            }}
            px={{ md: 12, lg: 36, xl: isSingle ? 36 : 6 }}
            gap={{ xl: !isSingle ? 8 : 0 }}
          >
            <RenderItem
              id={id}
              isSingle={isSingle}
              isDouble={isDouble}
              title={title}
              image={image}
              body={body}
              buttons={buttons}
            />
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
