import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Marker } from "react-map-gl";

const accessToken =
  "pk.eyJ1Ijoic3VuZWtpYnMiLCJhIjoiY2xpYTF3anRmMjgwbjNrbWxybGRmZHlyMSJ9.P2Wu_QkEJRNe7G5pmmEweQ";

interface MapProps {
  location: Record<string, any>;
}

export default function Mapbox({ location }: MapProps) {
  const lng = location.geometry.coordinates[0];
  const lat = location.geometry.coordinates[1];

  const [viewState, setViewState] = useState({
    longitude: lng,
    latitude: lat,
    zoom: 14,
  });

  return (
    <Box pos="relative" w="100%" h="100%">
      <Map
        reuseMaps
        {...viewState}
        mapboxAccessToken={accessToken}
        mapStyle="mapbox://styles/sunekibs/ckxzyity8d2xs14qrku7nq4u5"
        onMove={(evt) => setViewState(evt.viewState)}
        minZoom={5}
        maxZoom={16}
      >
        <Marker longitude={lng} latitude={lat} color="red" />
      </Map>
    </Box>
  );
}
